module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/locale","languages":["cs-cz","de-de","el-gr","en-au","en-us","en-gb","es-es","fr-fr","hu-hu","it-it","pl-pl","ro-ro","tr-tr","zh-tw","es-mx","es-ar","ja-jp","ko-kr","pt-br","ru-ru","th-th"],"defaultLanguage":"en-us","redirect":false},
    },{
      plugin: require('../node_modules/@riotgames/gatsby-plugin-geotargeting/gatsby-browser.ts'),
      options: {"plugins":[],"supportedLocales":["cs-cz","de-de","el-gr","en-au","en-us","en-gb","es-es","fr-fr","hu-hu","it-it","pl-pl","ro-ro","tr-tr","zh-tw","es-mx","es-ar","ja-jp","ko-kr","pt-br","ru-ru","th-th"],"defaultLanguage":"en-us"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KBF2RWH","includeInDevelopment":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"tellstones","short_name":"tellstones","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/tellstones-favicon-blue.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"66daa072275f68132e113ae41d7c9865"},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"src/gatsby"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    }]
